.extra-word {
  padding-right: 100px !important;
}

.landing {
  /* background-color: #bfd2d2; */
  background-image: url("/src/assets/hello.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.landing3 {
  background-color: #bfd2d2;
}

.new-hold {
  width: 60%;
  margin-bottom: 50px;
}

.benefit-block {
  padding-bottom: 50px;
  padding-top: 50px;
}
@media (max-width: 480px) {
  .title-holder {
    text-align: center;
    padding-top: 100px;
  }

  .new-hold {
    width: 100%;
  }
  .extra-word {
    text-align: center !important;
    padding: 20px !important;
  }

  h1 {
    font-size: 30px !important;
  }

  h2 {
    font-size: 30px !important;
  }
}

@media (min-width: 490px) {
  .hworks {
    padding-top: 100px;
  }
}
